import styled from '@emotion/styled'

import mq from 'styles/breakpoints'
import BackgroundImage from 'images/jpg/start_experiencejoy_mangirl.jpg'

export const TopTitle = styled.div`
  color: #213054;
  text-align: center;
  margin-top: -43%;

  h1 {
    font-size: 7rem;
  }
  h3 {
    font-size: 3rem;
  }

  ${mq['xxl']} {
    h1 {
      font-size: 6rem;
    }
    h3 {
      font-size: 2rem;
    }
  }

  ${mq['lg']} {
    margin-top: -40%;
  }

  ${mq['md']} {
    margin-top: -34%;
    h1 {
      font-size: 3rem;
    }
    h3 {
      font-size: 1.5rem;
    }
  }

  ${mq['sm']} {
    margin-top: -5%;
    h1 {
      font-size: 3rem;
    }
    h3 {
      font-size: 2rem;
    }
  }
`

export const ImageContainer = styled.div`
  margin-top: -12rem;
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center -260%;
  width: 100%;
  padding-top: 55%;
  height: 100vh;

  ${mq['md']} {
    margin-top: -13rem;
    background-position: center center;
  }
`

export const SectionProductLineUp = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2rem;
  padding-bottom: 5rem;
  width: 90%;
  margin-left: 5%;
  background-color: white;
  color: #213054;

  ${mq['sm']} {
    width: 100%;
    margin-left: 0rem;
    flex-direction: column;
    align-items: center;
  }
`
export const ProductTitle = styled.div`
  width: 100%;
  padding-top: 10rem;
  padding-bottom: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
  h1 {
    font-size: 7rem;
  }
`

export const ProductContainer = styled.div`
  width: 33%;

  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    margin-bottom: 1rem;
    text-align: center;
    font-size: 3.8rem;
    font-weight: 400;
  }

  h4 {
    padding-left: 10rem;
    padding-right: 10rem;
    text-align: center;
    font-size: 2.1rem;
    font-weight: 400;
    margin-bottom: 5rem;
    color: #b9b9b9;
    span {
      color: #0080fa;
    }
  }

  ${mq['xxl']} {
    h2 {
      font-size: 2.5rem;
    }

    h4 {
      padding-left: 3rem;
      padding-right: 3rem;
      font-size: 1.75rem;
    }
  }

  ${mq['xl']} {
    h2 {
      font-size: 2.25rem;
    }

    h4 {
      font-size: 2rem;
    }
  }

  ${mq['md']} {
    width: 100%;

    h2 {
      font-size: 3.5rem;
    }

    h4 {
      padding-left: 3rem;
      padding-right: 3rem;
      font-size: 2.15rem;
      counter-reset: #213054;
    }
  }
`

export const ShowcaseContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  width: 100%;
  margin-left: 0%;
  margin-top: 1rem;
  margin-bottom: 0rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  color: #213054;
  ${mq['xxl']} {
    justify-content: space-around;
  }

  img {
    padding: 0em 2em;
  }
`

export const ShowcaseTitle = styled.div`
  width: 95%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center;

  h1 {
    font-size: 7rem;
    color: rgb(184, 184, 184);
  }

  h2 {
    font-size: 4rem;
  }

  ${mq['xxl']} {
    h1 {
      font-size: 5rem;
    }

    h2 {
      font-size: 3rem;
    }
  }

  ${mq['md']} {
    padding-left: 0rem;
    padding-right: 0rem;
    width: 90%;
    text-align: center;

    h1 {
      font-size: 4rem;
    }
  }
`

export const ShowcaseTextContainer = styled.div`
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center;
  width: 80rem;

  h2 {
    font-size: 4.5rem;
    font-weight: 500;
  }

  p {
    padding-top: 2rem;
    font-size: 2.25rem;
    font-weight: 400;
  }

  span {
    display: block;
    padding-top: 2rem;
    color: #0080fa;
    font-weight: 500;
  }

  ${mq['lg']} {
    width: 90%;

    h2 {
      font-size: 4rem;
      font-weight: 500;
    }

    p {
      font-size: 2.25rem;
      font-weight: 300;
    }
  }
`
export const AppLinksContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: -3rem;
  margin-bottom: 2rem;
  width: 70%;
  margin-left: 15%;

  ${mq['xl']} {
    width: 100%;
    margin-left: 0%;
  }
`
export const StoreIconContainer = styled.div`
  display: flex;
  align-items: center;
`

export const AppStoreText = styled.span`
  color: #0080fa;
  font-size: 1.5rem;
  margin-left: 1rem;
  margin-bottom: 0.2rem;

  a {
    text-decoration: none;
    color: #0080fa;
  }

  ${mq['sm']} {
    font-size: 1.4rem;
  }
`
export const ShowcaseImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
  height: auto;
`

export const FlexCenter = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 90%;
  align-content: center;

  ${mq['md']} {
    flex-direction: column;
    padding-top: 3rem;
  }
`

export const FlexCenterPerfectAccent = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  align-content: center;

  ${mq['md']} {
    flex-direction: column;
    padding-top: 3rem;
  }
`

export const ShowcaseRightContainer = styled.div`
  width: 60rem;
  padding-top: 15rem;
  padding-right: 2rem;
  color: #213054;

  h1 {
    font-size: 6rem;
    font-weight: 700;
    text-align: right;
    width: 100%;
  }

  h2 {
    font-size: 4.5rem;
    font-weight: 500;
    text-align: right;
    width: 100%;
  }

  h3 {
    color: #b9b9b9;
    font-size: 3rem;
    font-weight: 700;
    text-align: right;
    width: 100%;
  }

  p {
    padding-top: 2rem;
    font-size: 2.25rem;
    font-weight: 300;
    text-align: right;
    font-weight: 400;
    width: 100%;
  }

  span {
    display: block;
    padding-top: 2rem;
    color: #0080fa;
  }

  ${mq['xxl']} {
    h1 {
      font-size: 4rem;
      text-align: center;
    }

    h2 {
      font-size: 3rem;
      text-align: center;
    }

    p {
      font-size: 2rem;
      text-align: center;
    }
  }

  ${mq['xl']} {
    padding-top: 10rem;
    width: 100%;

    h1 {
      font-size: 3rem;
      text-align: center;
    }

    h2 {
      font-size: 2.5rem;
      text-align: center;
    }

    p {
      font-size: 1.65rem;
      text-align: center;
    }
  }

  ${mq['md']} {
    h1 {
      font-size: 4rem;
      text-align: center;
    }

    h2 {
      font-size: 3rem;
      text-align: center;
    }

    p {
      font-size: 2rem;
      text-align: center;
    }
  }
`

export const ShowcaseLeftContainer = styled.div`
  width: 35%;
  padding-top: 15rem;
  padding-right: 2rem;
  color: #213054;

  h1 {
    font-size: 6rem;
    font-weight: 700;
    text-align: left;
    width: 100%;
  }

  h2 {
    font-size: 4.5rem;
    font-weight: 500;
    text-align: left;
    width: 100%;
  }

  h3 {
    color: #b9b9b9;
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    width: 100%;
  }

  p {
    padding-top: 2rem;
    font-size: 2.25rem;
    font-weight: 400;
    text-align: left;
    width: 100%;
  }

  span {
    display: block;
    padding-top: 2rem;
    color: #0080fa;
    font-weight: 400;
  }

  ${mq['xxl']} {
    width: 45%;
    padding-top: 5rem;

    h1 {
      font-size: 4rem;
      text-align: center;
    }

    h2 {
      font-size: 3rem;
      text-align: center;
    }

    p {
      font-size: 2rem;
      text-align: center;
    }
  }

  ${mq['xl']} {
    width: 65%;
    padding-top: 5rem;

    h1 {
      font-size: 3rem;
      text-align: center;
    }

    h2 {
      font-size: 2rem;
      text-align: center;
    }

    p {
      font-size: 1.65rem;
      text-align: center;
    }
  }
  ${mq['md']} {
    width: 100%;

    h1 {
      font-size: 4rem;
      text-align: center;
    }

    h2 {
      font-size: 3rem;
      text-align: center;
    }

    p {
      font-size: 2rem;
      text-align: center;
    }
  }
`

export const ShowcaseContainerPerfectAccent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  width: 100%;
  margin-left: 0%;
  padding-top: 2rem;
  padding-bottom: 5rem;
  color: #213054;

  ${mq['xxl']} {
    justify-content: space-around;
  }

  ${mq['md']} {
    padding-top: 5rem;
  }
`

export const ShowcaseImageNew = styled.div`
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  ${mq['md']} {
    width: 100%;
  }
`

export const CareerSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-top: 2rem;
  margin-left: 10%;
  padding-top: 2rem;
  padding-bottom: 0srem;
  color: #213054;

  ${mq['xxl']} {
    justify-content: space-around;
  }

  ${mq['md']} {
    width: 100%;
    margin-left: 0%;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgb(250, 250, 250);
  color: #213054;

  ${mq['xxl']} {
    padding-bottom: 5rem;
  }

  ${mq['xl']} {
    flex-direction: column;
  }
`

export const CareerImage = styled.div`
  max-width: max-content;

  img {
    max-width: 100%;

    ${mq['xl']} {
      width: 100%;
      height: auto;
    }
  }
`

export const CareerTextContainer = styled.div`
  margin-left: 20px;
  max-height: 812px;
`

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 10rem;
  margin-bottom: 0rem;

  ${mq['xxl']} {
    margin-top: 5rem;
  }

  ${mq['lg']} {
    justify-content: space-around;
  }
`

export const FlexColumn = styled.div`
  flex-direction: column;
  justify-content: center;
  width: 50%;
  display: inline;

  h1 {
    display: inline;
    padding-right: 1rem;
    font-size: 6rem;
    font-weight: 300;
    color: #b9b9b9;
  }

  h3 {
    display: inline;
    font-size: 2rem;
  }

  p {
    font-size: 2rem;
    padding-left: 5rem;
    padding-bottom: 5rem;
  }

  h6 {
    font-size: 4rem;
    color: #0080fa;
    margin-top: 2rem;
  }

  h5 {
    color: #0080fa;
    font-size: 2.1rem;
    margin-top: 1.5rem;
    margin-left: 0.5rem;
  }

  ${mq['xxl']} {
    margin-right: 0;
    width: 33rem;

    h6 {
      font-size: 3rem;
    }

    p {
      font-size: 1.75rem;
    }

    h5 {
      font-size: 1.8rem;
    }
  }

  ${mq['xl']} {
    width: 50%;
  }

  ${mq['sm']} {
    width: 100%;
  }
`

export const SubscriptionPlans = styled.div`
  background: -webkit-gradient(
    linear,
    left top,
    0 200,
    from(#f5f5f5),
    to(white)
  );

  display: flex;
  flex-direction: column;
  align-items: center;
  color: #213054;
  margin-bottom: 10rem;
  margin-top: 15rem;

  h1 {
    font-size: 6.2rem;
    font-weight: 300;
    padding-top: 11rem;
  }

  h4 {
    font-size: 3.2rem;
    font-weight: 400;
    margin-top: 1rem;
    text-align: center;
    span {
      color: #0080fa;
    }
  }

  ${mq['lg']} {
    h1 {
      font-size: 5rem;
    }

    h4 {
      font-size: 2.5rem;
    }
  }

  ${mq['sm']} {
    h1 {
      font-size: 4.5rem;
    }

    h4 {
      font-size: 2rem;
    }
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`
